import React, { useState, useEffect } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { useMount, useSearchParam } from 'react-use'
import { connect } from 'react-redux'

import { updateSettings } from '../store/settings'

import { Layout, Footer, Login, MetaTags, Modal } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { lightRed, orange } from '../styles/colors'

import logo from '../assets/images/logo.png'

import { isAuthenticated } from '../services/auth';
import { media, isClient } from '../styles/utils';

const Index = (props) => {
    const data = JSON.parse(props.data.wordpressLandingV2.acf_json)
    const globalData = JSON.parse(props.data.wordpressOptions.acf_json)
    const modalManager = Modal.useModals()    
    const showLogin = useSearchParam('login');

    useMount(() => {
        if (isAuthenticated()) navigate('app/home');

        if (isClient() && window.fbq) {
            window.fbq('track', 'ViewContent');
            toggleLogin?.()
        }

        setTimeout(() => {
            if (data.audio_file) {
                props.updateSettings({
                    audio: data.audio_file.url
                })
            }
        }, 3000);
    })
    
    if (isAuthenticated()) { 
        return <></> 
    }

    const toggleLogin = () => {
        if (showLogin !== 'true' || !data) return;

        const item = sections(data)?.[1];

        return modalManager.open(modal => (
            <Modal 
                {...item}
                {...modal}
                updateSettings={props.updateSettings}
                originalAudio={data.audio_file.url}
                trackOpen
            />
        ))
    }

    const renderIntro = () => {
        return (
            <Intro>
                <Heading
                    dangerouslySetInnerHTML={{__html: 'ADVENTURES IN THE FIELD '}}
                />

                <Text
                    dangerouslySetInnerHTML={{__html: data.tagline}}
                />
            </Intro>
        )
    }

    const toggleModal = (item) => {
        return modalManager.open(modal => (
            <Modal 
                {...item}
                {...modal}
                updateSettings={props.updateSettings}
                originalAudio={data.audio_file.url}
                trackOpen
            />
        ))
    }

    const renderSection = (item, i) => {
        return (
            <Section
                as={item.type == 'link' && Link}
                to={item.link}
                layout={sectionLayouts[i]}
                onClick={() => {
                    item.type == 'modal' && toggleModal(item)
                }}
            >
                <Info>
                    <Heading>
                        {item.title}
                    </Heading>
                </Info>
                <SectionBackground/>
            </Section>
        )
    }

    const renderSections = () => {
        const items = sections(data).map((item, i) => {
            return renderSection(item, i)
        })

        return (
            <SectionWrapper>
                <Sections>
                    {items}
                </Sections>
            </SectionWrapper>
        )
    }

    const renderProfile = () => {
        if (!data.use_profile_image) return;

        return (
            <Profile
                image={data.profile_image && data.profile_image?.sizes?.medium2}
            >
                <Tooltip>
                    <Heading
                        dangerouslySetInnerHTML={{__html: data.profile_tootip_text}}
                    />
                </Tooltip>
            </Profile>
        )
    }
    
    
    return (
        <Layout>

            {globalData && (
                <MetaTags 
                    title={`Adventures in the Field`}
                    description={globalData.meta_description_default}
                    url={`https://adventuresinthefield.com`}
                    //image={globalData.social_sharing_image.sizes.medium2}
                />
            )}

            <Wrapper
                bgImage={data.background_image?.sizes?.full_width}
                mobileBgImage={data.background_image_mobile?.sizes?.full_width}
            > 
                <Logo 
                    to={'/'}
                />

                {renderIntro()}
                {renderSections()}
                {renderProfile()}
            </Wrapper>
            <Footer/>
        </Layout>	
    )
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Text = styled.div``
const Info = styled.div``

// Layput

const Wrapper = styled.div`
    ${bgImage}
    background-image: url(${props => props.bgImage});
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ${media.phone`
        min-height: 1150px;
        position: relative;
    `}

    ${props => {
        if (props.mobileBgImage) return css`
            ${media.phone`
                background-image: url(${props.mobileBgImage});
            `}
        `
    }}
`

const Container = styled.div`
	${container}
	${padding}
    position: relative;
`

// Logo

const Logo = styled(Link)`
    position: absolute;
    top: 24px;
    left: 24px;
    ${bgImage}
    background-image: url(${logo});
    width: 165px;
    height: 134px;
    z-index: 2;
    
    ${media.phone`
        width: 88px;
        height: 71px;
        top: 5px;
        left: 5px;
    `}
`

// Intro

const Intro = styled.div`
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;

    ${Heading} {
        font-size: 36px;
        line-height: 52px;
        letter-spacing: 0.5em;
        color: ${orange};
        font-weight: 200;
        width: 440px;
        text-align: center;
    }

    ${Text} {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.1em;
        font-family: 'LatoWebBoldItalic';
        color: #636363;
        margin-top: -10px;
        text-align: center;
    }

    ${media.phone`
        text-align: center;
        width: 90vw;
        top: 80px;

        display: flex;
        flex-direction: column;
        align-items: center;

        ${Heading} {
            font-size: 24px;
            line-height: 1.35em;
            width: auto;
        }

        ${Text} {
            font-size: 13px;
            line-height: 17px;
            letter-spacing: 0.05em;
        }
    `}
` 

// Sections

const Sections = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    ${media.phone`
        margin-top: 24px;
    `}
`

// Section


const SectionWrapper = styled.div`
    width: 1600px;
    height: 100vh;
    position: fixed;
    left: calc((100vw - 1600px) / 2);

    ${media.tablet`
        transform: scale(0.8);
    `}

    ${media.phone`
        position: relative;
        height: 100%;
        left: auto;
        width: 100vw;
        transform: scale(1);
    `}
`

const SectionBackground = styled.div``

const Section = styled.div`
    ${props => props.layout}
    width: 229px;
    height: 229px;
    display: flex;
    cursor: pointer;

    ${media.phone`
        width: 195px;
        height: 195px;
    `}

    ${SectionBackground} {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0%;
        background-image: url(${require('../assets/images/ring.svg')});
        ${bgImage}
    }

    ${Info} {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        ${Subheading},
        ${Heading} {
            font-family: 'LatoWebBold';
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            margin: 0;
            color: #444444;
            ${media.tablet`
                font-size: 11px;
            `}
        }

    }
`

// Profile

const Tooltip = styled.div`
    width: 221px;
    height: 63px;
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: white;
    position: relative;

    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translate(-50%, 100%);

    ${Heading} {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.05em;
        color: #444444;
        font-family: 'LatoWebBold';
    }

    &::before {
        content: '';
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;

        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
    }
`

const Profile = styled.div`
    width: 270px;
    height: 270px;
    border-radius: 50%;
    background-image: url(${props => props.image});
    ${bgImage}
    display: flex;

    position: absolute;
    bottom: 10%;
    right: 8%;

    ${Tooltip} {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.25s ease;
    }

    ${media.tablet`
        transform: scale(0.8);
    `}
    
    &:hover {
        ${Tooltip} {
            opacity: 1;
        }
    }

    ${media.phone`
        position: absolute;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: 68px;
        width: 195px;
        height: 195px;
    `}
`

// Section data

const sections = (data) => {
    return [
        {
            title: 'Try Now',
            type: 'link',
            link: 'app/the-priming'
        },
        {
            title: 'Member',
            type: 'modal',
            modal_layout: 'login',
            modal_title: data.member_title
        },
        {
            title: 'Watch',
            type: 'modal',
            modal_layout: 'video',
            modal_title: data.watch_title,
            video: data.watch_video_embed_src
        },
        {
            title: 'Listen',
            type: 'modal',
            modal_layout: 'audio',
            modal_title: data.listen_title,
            audio: data.listen_audio
        },
        {
            title: 'Read',
            type: 'modal',
            modal_layout: 'text',
            modal_title: data.read_title,
            text: data.read_text
        },
    ]
}

const sectionLayouts = [
    css`
        position: absolute;
        right: 21.25%;
        top: 31.8%;

        ${SectionBackground} {
            transform: rotate(-109.87deg);
        }
      
        ${media.phone`
            left: calc(50% - 98px + 72px);
            top: 475px;
        `}
    `,
    css`
        position: absolute;
        right: 32.5%;
        top: 39%;

        ${SectionBackground} {
            transform: rotate(-2.84deg);
        }

        ${media.phone`
            left: calc(50% - 98px - 72px);
            top: 300px;
        `}   
    `,
    css`
        position: absolute;
        right: 46.35%;
        top: 57.81%;

        ${SectionBackground} {
            transform: rotate(-115.99deg);
        }

      

        ${media.phone`
            left: calc(50% - 98px + 72px);
            top: 227px;
        `} 
    `,
    css`
        position: absolute;
        right: 57.75%;
        top: 65.82%;

        ${SectionBackground} {
            transform: rotate(-31.27deg);
        }

        ${media.phone`
            left: calc(50% - 98px + 45px);
            top: 630px;
        `}
    `,
       css`
        position: absolute;
        right: 63.31%;
        top: 49.12%;

        ${SectionBackground} {
            transform: rotate(153.94deg);
        }

        ${media.phone`
            left: calc(50% - 98px - 84px);  
            top: 542px;
        `}
    `,
]

export const query = graphql`
	query {
		wordpressLandingV2 {
			acf_json	
		}
        wordpressOptions {
            acf_json
        }
	}
`

const mapDispatchToProps = dispatch => ({
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

export default connect(null, mapDispatchToProps)(Index);